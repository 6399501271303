import React from "react";
import { SERVICES } from "../../../constants/services";
import { Button } from "../../shared/movingBorders/MovingBorders";

const Services = () => {
	return (
		<div className="md:py-16 lg:py-16 px-56 py-10 w-full" id="services">
			<h2 className="heading">
				<span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
					Services
				</span>{" "}
				Offered
			</h2>

			<div className="w-full mt-12 grid lg:grid-cols-4 grid-cols-1 gap-10">
				{SERVICES.map((card: any) => (
					<Button
						key={card.id}
						//   random duration will be fun , I think , may be not
						duration={Math.floor(Math.random() * 10000) + 10000}
						borderRadius="1.75rem"
						style={{
							//   add these two
							//   you can generate the color from here https://cssgradient.io/
							// background: "rgb(4,7,29)",
							background: "#171721",
							// backgroundColor: "linear-gradient(90deg, rgba(4,7,29,1) 0%, rgba(12,14,35,1) 100%)",
							// add this border radius to make it more rounded so that the moving border is more realistic
							borderRadius: "calc(1.75rem* 0.96)"
						}}
						// remove bg-white dark:bg-slate-900
						className="flex-1 dark:text-white  dark:border-slate-800 border border-[#E2CBFF]"
					>
						<div className="flex lg:flex-row flex-col lg:items-center p-3 py-6 md:p-5 lg:p-10 gap-2">
							<img
								src={card.thumbnail}
								alt={card.thumbnail}
								className="lg:w-32 md:w-20 w-16"
								// width={100}
								// height={60}
							/>
							<div className="lg:ms-5">
								<h1 className="text-start text-xl md:text-2xl font-bold">{card.title}</h1>
								<p className="text-start text-slate-400 mt-3 font-semibold">{card.desc}</p>
							</div>
						</div>
					</Button>
				))}
			</div>
		</div>
	);
};

export default Services;
