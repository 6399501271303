import React from "react";
import {
	Nav,
	NavLink,
	NavbarContainer,
	Span,
	NavLogo,
	NavItems,
	GitHubButton,
	ButtonContainer,
	MobileIcon,
	MobileMenu,
	MobileLink
} from "./NavbarStyledComponent";
import { DiCssdeck } from "react-icons/di";
import { FaBars } from "react-icons/fa";
import { BIOGRAPHY } from "../../../constants/biography";
import { Close, CloseRounded } from "@mui/icons-material";
import { useTheme } from "styled-components";
import TopRibbon from "../topRibbon/TopRibbon";
import { SourishMukherjeeBrandLogo } from "../";
import { SourishMukherjeeLogo } from "../../../assets";

const Navbar = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	const theme = useTheme();
	return (
		<>
			<TopRibbon />
			<Nav>
				<NavbarContainer>
					<NavLogo to="/">
						<a
							style={{
								display: "flex",
								alignItems: "center",
								color: "white",
								// marginBottom: "20px",
								cursor: "pointer"
							}}
						>
							<img
								style={{ width: "35%", height: "auto" }}
								src={SourishMukherjeeLogo}
								alt="Sourish Mukherjee Logo"
							/>
							<Span className="text-gradient" style={{ marginLeft: "-1.7rem" }}>
								{"Portfolio"}
							</Span>
						</a>
					</NavLogo>
					<MobileIcon>
						<FaBars
							onClick={() => {
								setIsOpen(!isOpen);
							}}
						/>
					</MobileIcon>
					<NavItems>
						<NavLink href="#about">About</NavLink>
						<NavLink href="#services">Services</NavLink>
						<NavLink href="#skills">Skills</NavLink>
						<NavLink href="#experience">Experience</NavLink>
						<NavLink href="#projects">Projects</NavLink>
						<NavLink href="#education">Education</NavLink>
					</NavItems>
					<ButtonContainer>
						<GitHubButton href={BIOGRAPHY.linkedin} target="_blank">
							Connect me on LinkedIn
						</GitHubButton>
					</ButtonContainer>
					{isOpen && (
						<MobileMenu isOpen={isOpen}>
							<MobileLink
								href="#about"
								onClick={() => {
									setIsOpen(!isOpen);
								}}
							>
								About
							</MobileLink>
							<MobileLink
								href="#services"
								onClick={() => {
									setIsOpen(!isOpen);
								}}
							>
								Services
							</MobileLink>
							<MobileLink
								href="#skills"
								onClick={() => {
									setIsOpen(!isOpen);
								}}
							>
								Skills
							</MobileLink>
							<MobileLink
								href="#experience"
								onClick={() => {
									setIsOpen(!isOpen);
								}}
							>
								Experience
							</MobileLink>
							<MobileLink
								href="#projects"
								onClick={() => {
									setIsOpen(!isOpen);
								}}
							>
								Projects
							</MobileLink>
							<MobileLink
								href="#education"
								onClick={() => {
									setIsOpen(!isOpen);
								}}
							>
								Education
							</MobileLink>
							<GitHubButton
								style={{
									padding: "10px 16px",
									background: `${theme.primary}`,
									color: "white",
									width: "max-content"
								}}
								href={BIOGRAPHY.linkedin}
								target="_blank"
							>
								Connect me on LinkedIn
							</GitHubButton>
						</MobileMenu>
					)}
				</NavbarContainer>
			</Nav>
		</>
	);
};

export default Navbar;
