import React from "react";
import { SourishMukherjeeLogo } from "../../../assets";

import "./smLogo.css";

const SourishMukherjeeBrandLogo: React.FC = (): JSX.Element => {
	return (
		<div className="sourish-mukherjee-logo-container">
			<img style={{ width: "25%", height: "auto" }} src={SourishMukherjeeLogo} alt="Sourish Mukherjee Logo" />
		</div>
	);
};

export default SourishMukherjeeBrandLogo;
