import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { motion } from "framer-motion";
import styled from "styled-components";

import "react-vertical-timeline-component/style.min.css";

import { educations_v2 } from "../../../constants/biography";
import { SectionWrapper } from "../../../hoc";
import { textVariant } from "../../../utils/motion";

const EducationCard = ({ experience }: any) => {
	return (
		<VerticalTimelineElement
			contentStyle={{
				background: "#1d1836",
				color: "#fff"
			}}
			contentArrowStyle={{ borderRight: "7px solid  #818589" }}
			date={experience.date}
			iconStyle={{ background: experience.iconBg }}
			icon={
				<div className="flex justify-center items-center w-full h-full">
					<img
						src={experience.icon}
						alt={experience.university_name}
						className="w-[60%] h-[60%] object-contain"
					/>
				</div>
			}
		>
			<div>
				<h3 className="text-white text-[24px] font-bold">{experience.title}</h3>
				<p className="text-secondary text-[16px] font-semibold" style={{ margin: 0 }}>
					{experience.university_name}
				</p>
			</div>

			<div>
				<span className="text-secondary text-[16px] font-semibold" style={{ margin: 0 }}>
					Institute:
				</span>
				<span className="text-secondary text-[16px] font-semibold" style={{ margin: 0 }}>
					{experience.institute_name}
				</span>
			</div>

			{/* <ul className="mt-5 list-disc ml-5 space-y-2">
				{experience.points.map((point: any, index: any) => (
					<li key={`experience-point-${index}`} className="text-white-100 text-[14px] pl-1 tracking-wider">
						{point}
					</li>
				))}
			</ul> */}
		</VerticalTimelineElement>
	);
};

const Wrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	width: 100%;
	max-width: 1350px;
	padding: 40px 0px 0px 0px;
	gap: 12px;
	@media (max-width: 960px) {
		flex-direction: column;
	}
`;

const Title = styled.div`
	font-size: 42px;
	text-align: center;
	font-weight: 600;
	margin-top: 20px;
	color: ${({ theme }) => theme.text_primary};
	@media (max-width: 768px) {
		margin-top: 12px;
		font-size: 32px;
	}
`;

const Desc = styled.div`
	font-size: 18px;
	text-align: center;
	max-width: 600px;
	color: ${({ theme }) => theme.text_secondary};
	@media (max-width: 768px) {
		margin-top: 12px;
		font-size: 16px;
	}
`;

const Education_v2 = () => {
	return (
		<>
			<div id="education">
				<Wrapper>
					<Title>Education</Title>
					<Desc>
						My education has been a journey of self-discovery and growth. My educational details are as
						follows.
					</Desc>
				</Wrapper>
				{/* <motion.div variants={textVariant({ delay: 0.0 })} animate="visible">
				<p className={"text-white text-center"}>What I have done so far</p>
				<h2 className={"text-white text-center"}>Work Experience.</h2>
			</motion.div> */}

				<div className="mt-20 flex flex-col">
					<VerticalTimeline>
						{educations_v2.map((experience, index) => (
							<EducationCard key={`experience-${index}`} experience={experience} />
						))}
					</VerticalTimeline>
				</div>
			</div>
		</>
	);
};

export default SectionWrapper(Education_v2, "work");
