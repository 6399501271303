import React from "react";
import HeroBgAnimation from "./HeroBgAnimation/HeroBgAnimation";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "@react-spring/web";
import { FaLocationArrow } from "react-icons/fa6";
import {
	HeroContainer,
	HeroBg,
	HeroLeftContainer,
	Img,
	HeroRightContainer,
	HeroInnerContainer,
	TextLoop,
	Title,
	Header2,
	Span,
	SubTitle,
	ResumeButton
} from "./HeroStyle";
import { SourishMukherjeeHeroImage } from "../../../assets";
import Typewriter from "typewriter-effect";
import { BIOGRAPHY } from "../../../constants/biography";
import { KnowMoreButton, MagicButton } from "../../shared";

import "./heroSection.css";

interface FadeProps {
	children: React.ReactElement;
	in?: boolean;
	onClick?: any;
	onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
	onExited?: (node: HTMLElement, isAppearing: boolean) => void;
	ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
	const { children, in: open, onClick, onEnter, onExited, ownerState, ...other } = props;
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter(null as any, true);
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited(null as any, true);
			}
		}
	});

	return (
		<animated.div ref={ref} style={style} {...other}>
			{React.cloneElement(children, { onClick })}
		</animated.div>
	);
});

const style = {
	position: "absolute" as const,
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	// width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4
};

const HeroSection = () => {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<div id="about">
			<HeroContainer>
				<HeroBg>
					<HeroBgAnimation />
				</HeroBg>
				<HeroInnerContainer>
					<HeroLeftContainer id="Left">
						<Header2>Hi, This is</Header2>
						<Title>
							{/* <br /> */}
							<div className="text-gradient">{BIOGRAPHY.name}</div>
						</Title>
						<TextLoop>
							I am a
							<Span className="gradient-text">
								<Typewriter
									options={{
										strings: BIOGRAPHY.roles,
										autoStart: true,
										loop: true
									}}
								/>
							</Span>
						</TextLoop>
						<SubTitle>{BIOGRAPHY.description}</SubTitle>
						{/* <div className="know-more-container" onClick={handleOpen}>
							...Know More
						</div> */}
						<Modal
							aria-labelledby="spring-modal-title"
							aria-describedby="spring-modal-description"
							open={open}
							onClose={handleClose}
							closeAfterTransition
							slots={{ backdrop: Backdrop }}
							slotProps={{
								backdrop: {
									TransitionComponent: Fade
								}
							}}
						>
							<Fade in={open}>
								<Box sx={style}>
									<div className="what-is-text"> {"About my Journey"}</div>
									<div className="main-para">
										With a profound passion for Full-Stack Development, Cloud Computing, and DevOps,
										I bring a diverse set of skills and experiences to the table. My career journey
										has been driven by a relentless pursuit of excellence, continuous learning, and
										a commitment to innovation.
									</div>
									<div className="second-text">
										As a Product Architect and Senior Software Engineer, I specialize in designing
										and implementing scalable, high-performance solutions that drive business growth
										and enhance user experiences. My expertise in the AWS ecosystem and the MERN
										stack enables me to build robust, end-to-end applications that meet the complex
										needs of modern enterprises.
									</div>
									<div className="second-para">
										Holding an Executive Post Graduate Diploma in Business Management from IIM
										Kashipur, I possess a strategic mindset that bridges the gap between technology
										and business. This unique combination allows me to advise on product
										development, streamline processes, and deliver impactful solutions that align
										with organizational goals.
									</div>
									<div className="second-para">
										I am dedicated to contributing to my organization by leveraging my technical
										skills, sharing knowledge, and collaborating with cross-functional teams. My
										academic background, including an MCA from MAKAUT and a PGDCA from IGNOU,
										provides a solid foundation in computer science and application development.
									</div>
									<div className="second-para">
										Beyond my professional endeavors, I am also a martial artist, which instills
										discipline, focus, and resilience—qualities that I carry into my work.
									</div>
									<div className="second-para">
										Lets connect and explore opportunities to innovate and achieve new heights
										together!
									</div>
								</Box>
							</Fade>
						</Modal>
						{/* <ResumeButton href={BIOGRAPHY.resume} target="display">
							Check Resume
						</ResumeButton> */}
						<a href="#projects">
							<MagicButton title="Explore my Projects" icon={<FaLocationArrow />} position="right" />
						</a>
					</HeroLeftContainer>

					<HeroRightContainer id="Right">
						<Img src={SourishMukherjeeHeroImage} alt="hero-image" />
					</HeroRightContainer>
				</HeroInnerContainer>
			</HeroContainer>
		</div>
	);
};

export default HeroSection;
