"use client";
import React from "react";
import { motion, useAnimationFrame, useMotionTemplate, useMotionValue, useTransform } from "framer-motion";
import { useRef } from "react";
import { cn } from "../../../lib/utils";

export function Button({
	borderRadius = "1.75rem",
	children,
	as: Component = "button",
	containerClassName,
	borderClassName,
	duration,
	className,
	...otherProps
}: {
	borderRadius?: string;
	children: React.ReactNode;
	as?: any;
	containerClassName?: string;
	borderClassName?: string;
	duration?: number;
	className?: string;
	[key: string]: any;
}) {
	return (
		<Component
			className={cn(
				// remove h-16 w-40, add  md:col-span-2
				"bg-transparent relative text-xl p-[1px] overflow-hidden md:col-span-2 md:row-span-1",
				containerClassName
			)}
			style={{
				borderRadius: borderRadius
			}}
			{...otherProps}
		>
			<div className="absolute inset-0 rounde-[1.75rem]" style={{ borderRadius: `calc(${borderRadius} * 0.96)` }}>
				<MovingBorder duration={duration} rx="30%" ry="30%">
					<div
						className={cn(
							"h-20 w-20 opacity-[0.8] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]",
							borderClassName
						)}
					/>
				</MovingBorder>
			</div>

			<div
				className={cn(
					"relative bg-slate-900/[0.] border border-slate-800 backdrop-blur-xl text-white flex items-center justify-center w-full h-full text-sm antialiased",
					className
				)}
				style={{
					borderRadius: `calc(${borderRadius} * 0.96)`
				}}
			>
				{children}
			</div>
		</Component>
	);
}

export const MovingBorder = ({
	children,
	duration = 2000,
	rx,
	ry,
	...otherProps
}: {
	children: React.ReactNode;
	duration?: number;
	rx?: string;
	ry?: string;
	[key: string]: any;
}) => {
	const pathRef = useRef<any>();
	const progress = useMotionValue<number>(0);

	useAnimationFrame((time: any) => {
		const length = pathRef.current?.getTotalLength();
		if (length) {
			const pxPerMillisecond = length / duration;
			progress.set((time * pxPerMillisecond) % length);
		}
	});

	const x = useTransform(progress, (val: any) => pathRef.current?.getPointAtLength(val).x);
	const y = useTransform(progress, (val: any) => pathRef.current?.getPointAtLength(val).y);

	const transform = useMotionTemplate`translateX(${x}px) translateY(${y}px) translateX(-50%) translateY(-50%)`;

	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				preserveAspectRatio="none"
				className="absolute h-full w-full"
				width="100%"
				height="100%"
				{...otherProps}
			>
				<rect fill="none" width="100%" height="100%" rx={rx} ry={ry} ref={pathRef} />
			</svg>
			<motion.div
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					display: "inline-block",
					transform
				}}
			>
				{children}
			</motion.div>
		</>
	);
};
