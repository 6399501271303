import { ThemeProvider } from "styled-components";
import { useState } from "react";
import { darkTheme, lightTheme } from "./global/theme/Themes";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import styled from "styled-components";
import { Navbar, Footer, CallToAction } from "./components/shared";
import { Education, Education_v2, Experience, Experience_v2, HeroSection, Projects, Skills } from "./components/pages";
import Services from "./components/pages/Services/Services";

const Body = styled.div`
	background-color: ${({ theme }) => theme.bg};
	width: 100%;
	overflow-x: hidden;
`;

const Wrapper = styled.div`
	background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%),
		linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
	width: 100%;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
`;

const App = () => {
	const [darkMode, setDarkMode] = useState(true);
	const [openModal, setOpenModal] = useState({ state: false, project: null });
	console.log(openModal);
	return (
		<ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
			<Router>
				<Navbar />
				<Body>
					<HeroSection />
					<Wrapper>
						<Services />
						{/* <Skills /> */}
						{/* <Experience /> */}
						{/* <Experience_v2 /> */}
					</Wrapper>
					{/* <Projects openModal={openModal} setOpenModal={setOpenModal} /> */}
					{/* <Wrapper> */}
					{/* <Education /> */}
					{/* <Education_v2 /> */}
					{/* <Contact /> */}
					{/* </Wrapper> */}
					{/* <CallToAction /> */}
					<Footer />
					{/* {openModal.state && <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />} */}
				</Body>
			</Router>
		</ThemeProvider>
	);
};

export default App;
