import React from "react";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import "./topRibbon.scss";
import { CONTACT_DETAILS } from "../../../constants/contactDetails";

const TopRibbon: React.FC = (): JSX.Element => {
	return (
		<div className="ribbon-main-container">
			<div className="left-section"></div>
			<div className="right-section">
				<div className="contact-links">
					<MdEmail color="white" />
					<span style={{ marginLeft: "0.3rem" }}>{CONTACT_DETAILS.email}</span>
				</div>
				{/* <div className="contact-links">
					<FaPhoneAlt color="white" />
					<span>{NEXCALIBER_DETAILS.NEXCALIBER_CONTACT}</span>
				</div> */}
			</div>
		</div>
	);
};

export default TopRibbon;
