import { Variants } from "framer-motion";

export const textVariant = ({ delay }: { delay: number }): Variants => {
	return {
		hidden: {
			y: -50,
			opacity: 0
		},
		show: {
			y: 0,
			opacity: 1,
			transition: {
				type: "spring",
				duration: 1.25,
				delay: delay
			}
		}
	};
};

export const fadeIn = (direction: any, type: any, delay: any, duration: any) => {
	return {
		hidden: {
			x: direction === "left" ? 100 : direction === "right" ? -100 : 0,
			y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
			opacity: 0
		},
		show: {
			x: 0,
			y: 0,
			opacity: 1,
			transition: {
				type: type,
				delay: delay,
				duration: duration,
				ease: "easeOut"
			}
		}
	};
};

export const zoomIn = (delay: any, duration: any) => {
	return {
		hidden: {
			scale: 0,
			opacity: 0
		},
		show: {
			scale: 1,
			opacity: 1,
			transition: {
				type: "tween",
				delay: delay,
				duration: duration,
				ease: "easeOut"
			}
		}
	};
};

export const slideIn = (direction: any, type: any, delay: any, duration: any) => {
	return {
		hidden: {
			x: direction === "left" ? "-100%" : direction === "right" ? "100%" : 0,
			y: direction === "up" ? "100%" : direction === "down" ? "100%" : 0
		},
		show: {
			x: 0,
			y: 0,
			transition: {
				type: type,
				delay: delay,
				duration: duration,
				ease: "easeOut"
			}
		}
	};
};

export const staggerContainer = (staggerChildren: any, delayChildren: any) => {
	return {
		hidden: {},
		show: {
			transition: {
				staggerChildren: staggerChildren,
				delayChildren: delayChildren || 0
			}
		}
	};
};
