import {
	AIVisionLogo,
	BusinessAdvisorLogo,
	CloudManagementLogo,
	IntegratedCRMLogo,
	MobileAppDevLogo,
	ProductArchitectureLogo,
	ProjectManagementLogo,
	SoftwareSolutions,
	StrategicPlanning,
	SystemDesignLogo,
	TechnologyConsultantLogo
} from "../assets";

export const SERVICES = [
	// {
	// 	id: 1,
	// 	title: "Software, Web Development, Mobile App Dev",
	// 	desc: "we specialize in creating custom websites and applications for individuals, businesses, and more.",
	// 	className: "md:col-span-2",
	// 	thumbnail: "/exp1.svg"
	// },
	{
		id: 1,
		title: "Software Solutions",
		desc: "Crafting custom websites and applications that perfectly align with the unique needs of individuals and businesses, blending creativity with cutting-edge technology to deliver exceptional digital experiences and more.",
		className: "md:col-span-2",
		thumbnail: SoftwareSolutions
	},
	{
		id: 2,
		title: "Mobile App Development",
		desc: "Led the development of innovative mobile applications, ensuring intuitive user experiences, high performance, and seamless integration across platforms.",
		className: "md:col-span-2", // change to md:col-span-2
		thumbnail: MobileAppDevLogo
	},
	// {
	// 	id: 2,
	// 	title: "Data Science As a Service Model",
	// 	desc: "Data Science as a Service (DSaaS) provides cloud-based analytics, extracting insights from data without in-house infrastructure. It enables cost-effective, data-driven decisions.",
	// 	className: "md:col-span-2", // change to md:col-span-2
	// 	thumbnail: "/ourServices/DataScienceLogo.png"
	// },
	{
		id: 3,
		title: "Integrated CRM Solutions",
		desc: "Integrated CRM solutions with advanced analytics and customizable dashboards, empowering businesses to make data-driven decisions, optimize customer interactions, and accelerate growth.",
		className: "md:col-span-2", // change to md:col-span-2
		thumbnail: IntegratedCRMLogo
	},
	{
		id: 4,
		title: "Cloud Management",
		desc: "Managed and optimized cloud infrastructure, driving cost efficiency, scalability, and seamless integration across AWS environments.",
		className: "md:col-span-2",
		thumbnail: CloudManagementLogo
	},
	{
		id: 5,
		title: "Product & System Architecture",
		desc: "Designing the structural blueprint of a product, focusing on how its components interact to meet business objectives and deliver a seamless user experience.",
		className: "md:col-span-2",
		thumbnail: ProductArchitectureLogo
	},
	{
		id: 6,
		title: "System Design",
		desc: "Crafting intuitive and impactful digital products with a blend of technical expertise and user-centric design, driving innovation from concept to completion",
		className: "md:col-span-2",
		thumbnail: SystemDesignLogo
	},

	{
		id: 7,
		title: "Business Advisor",
		desc: "Advised on strategic business decisions, optimizing processes and driving growth through data-driven insights and tailored strategies.",
		className: "md:col-span-2",
		thumbnail: BusinessAdvisorLogo
	},
	{
		id: 8,
		title: "Technology Solutions Engineer",
		desc: "Provided expert technology consulting, delivering tailored solutions that align with client goals, enhance digital transformation, and drive business value.",
		className: "md:col-span-2",
		thumbnail: TechnologyConsultantLogo
	},

	{
		id: 9,
		title: "Project Management",
		desc: "Led cross-functional teams to deliver complex projects on time and within budget, ensuring alignment with business objectives and stakeholder expectations.",
		className: "md:col-span-2",
		thumbnail: ProjectManagementLogo
	},
	{
		id: 10,
		title: "Strategic Planning",
		desc: "Developed and executed comprehensive business strategies to drive growth, optimize operations, and achieve long-term organizational goals.",
		className: "md:col-span-2",
		thumbnail: StrategicPlanning
	}
	// {
	//   id: 8,
	//   title: "Digital Marketing",
	//   desc: "Digital marketing uses online channels to promote products/services, engage customers with targeted content, and optimize strategies in real-time for measurable brand growth.",
	//   className: "md:col-span-2",
	//   thumbnail: "/ourServices/DigitalMarketing.png",
	// },
];
